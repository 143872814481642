<!--
 * @Description: 车辆用户管理系统管理系统 运维管理 优惠券领用统计 cuDiscountStatistic
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-02 09:32:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainbody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="优惠类型">
              <el-select class="search-input"
                         filterable
                         v-model="searchForm.ruleType"
                         placeholder="请选择优惠类型">
                <el-option v-for="item in discountTypeList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="活动名称">
              <el-input v-model="searchForm.activityName"
                        placeholder="请输入活动名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="领用渠道">
              <el-select class="search-input"
                         filterable
                         v-model="searchForm.receiveChannel"
                         placeholder="请选择领用渠道">
                <el-option v-for="item in channelsList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="领用时间">
              <el-date-picker v-model="times"
                              type="daterange"
                              align="right"
                              unlink-panels
                              range-separator="-"
                              start-placeholder="开始日期"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="topButton">
        <el-button type="primary"
                   @click="handleClickQuery">查 询</el-button>
        <el-button type="info"
                   style="margin-left:17px"
                   @click="resetForm">重 置</el-button>
      </el-row>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>优惠券领用</span>
        <el-button class="tableTitleButton"
                   type="text">导出</el-button>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-row>
                  <el-col :span="12">
                    <el-button @click="handleClickWatch(scope.row)"
                               type="text"
                               size="medium">
                      查 看
                    </el-button>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 优惠领用详情弹窗 -->
    <el-dialog title="优惠券领用详情"
               :visible.sync="distributeVisible"
               append-to-body>
      <el-form ref="popForm"
               :model="popForm"
               label-width="120px">
        <div class="pailie">
          <el-row class="popRow">
            <el-form-item label="活动名称"
                          prop="activityName">
              <el-input v-model="popForm.activityName"
                        readonly></el-input>
            </el-form-item>
          </el-row>
          <el-row class="popRow">
            <el-form-item prop="activityStatusDes"
                          label="活动状态">
              <el-input v-model="popForm.activityStatusDes"
                        readonly>
              </el-input>
            </el-form-item>
          </el-row>
          <el-row class="popRow">
            <el-form-item label="规则名称"
                          prop="ruleName">
              <el-input v-model="popForm.ruleName"
                        readonly></el-input>
            </el-form-item>
          </el-row>
          <el-row class="popRow">
            <el-form-item prop="createTime"
                          label="优惠创建时间">
              <el-input v-model="popForm.createTime"
                        readonly>
              </el-input>
            </el-form-item>
          </el-row>
          <el-row class="popRow">
            <el-form-item prop="ruleTypeDes"
                          label="规则类型">
              <el-input v-model="popForm.ruleTypeDes"
                        readonly>
              </el-input>
            </el-form-item>
          </el-row>
          <el-row class="popRow">
            <el-form-item prop="discount"
                          label="面额/折扣">
              <el-input v-model="popForm.discount"
                        readonly>
              </el-input>
            </el-form-item>
          </el-row>
          <el-row class="popRow">
            <el-form-item prop="receiveChannel"
                          label="领用渠道">
              <el-input v-model="popForm.receiveChannel"
                        readonly>
              </el-input>
            </el-form-item>
          </el-row>
          <el-row class="popRow">
            <el-form-item prop="cappedMoney"
                          label="封顶金额(元)">
              <el-input v-model="popForm.cappedMoney"
                        readonly>
              </el-input>
            </el-form-item>
          </el-row>
          <el-row class="popRow">
            <el-form-item prop="receiveTime"
                          label="领取时间">
              <el-input v-model="popForm.receiveTime"
                        readonly>
              </el-input>
            </el-form-item>
          </el-row>
          <el-row class="popRow">
            <el-form-item prop="useTime"
                          label="使用时间">
              <el-input v-model="popForm.useTime"
                        readonly>
              </el-input>
            </el-form-item>
          </el-row>
          <el-row class="popRow">
            <el-form-item prop="receiveTel"
                          label="领用人电话">
              <el-input v-model="popForm.receiveTel"
                        readonly>
              </el-input>
            </el-form-item>
          </el-row>
          <el-row class="popRow">
            <el-form-item prop="preferentialMoney"
                          label="优惠金额（元）">
              <el-input v-model="popForm.preferentialMoney"
                        readonly>
              </el-input>
            </el-form-item>
          </el-row>
        </div>
        <el-row style="margin-left:94%">
          <el-button type="info"
                     @click="handleClickWatchConfirm">确 认</el-button>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import dtTable from '@/components/table.vue'
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 10,
      },
      tableColumnList: [
        {
          prop: 'activityName',
          label: '活动名称',
        },
        {
          prop: 'startTime',
          label: '活动开始时间',
        },
        {
          prop: 'endTime',
          label: '活动结束时间',
        },
        {
          prop: 'receiver',
          label: '领用人',
        },
        {
          prop: 'receiveChannel',
          label: '领用渠道',
        },
        {
          prop: 'receiveTime',
          label: '领用时间',
        },
        {
          prop: 'ruleTypeDes',
          label: '优惠券类型',
        },
        {
          prop: 'discount',
          label: '优惠面额(元)/折扣(折)',
        },
        {
          prop: 'preferentialMoney',
          label: '优惠金额',
        },
      ],
      tableList: {
        list: [],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      discountTypeList: [],//优惠规则列表
      channelsList: [], //领用渠道列表
      searchForm: {
        ruleType: "",//优惠规则类型
        channelsList: "",//领用渠道
        recordId: "",//优惠领用记录id
        activityName: "",//活动名称
        startTime: "",//开始时间
        endTime: "",//结束时间
      },
      times: [],//领用事件
      pageNum: 1,
      pageSize: 10,
      total: 10, //初始化分页
      popForm: {},// 弹出框表单
      distributeVisible: false,// 弹出框是否打开
      activityTypeList: [],// 规则名称列表
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryDiscountType()
    this.queryChannels()
    this.queryActiveStatistic()
  },
  //方法集合
  methods: {
    // 渲染表格数据
    queryActiveStatistic () {
      this.searchForm['pageNum'] = this.pageNum
      this.searchForm['pageSize'] = this.pageSize
      this.$cuDiscountStatistic.selectCuDiscountStatistic(this.searchForm).then(res => {
        res.resultEntity.list.forEach(item => {
          item.cappedMoney = Number(Number(item.cappedMoney) / 100).toFixed(2)
          // 如果是优惠券
          if (item.ruleType === 1) {
            item.discount = Number(Number(item.discount) / 100).toFixed(2)
          }
        })
        this.tableList.list = res.resultEntity.list
      })
    },
    // 分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.getRuleList()
    },
    // 查询按钮
    handleClickQuery () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      if (this.times) {
        this.searchForm.startTime = this.times[0]
        this.searchForm.endTime = this.times[1]
      }
      else {
        this.searchForm.startTime = ''
        this.searchForm.endTime = ''
      }
      this.queryActiveStatistic()
    },
    // 重置按钮
    resetForm () {
      this.searchForm = {}
      this.times = []
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryActiveStatistic()
    },

    // 查看点击事件
    handleClickWatch (val) {
      this.distributeVisible = true
      this.popForm = val
    },
    // 点击事件确定按钮
    handleClickWatchConfirm () {
      this.distributeVisible = false
    },
    // 查询优惠类型列表
    queryDiscountType () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: 'F8898BDD4BCF4W12ABJKFSHGRFOUIGHLFCNFFDJDLIFHRFO',
          },
        ],
      }
      this.$queryDict.queryDict(info).then(res => {
        this.discountTypeList = res.resultEntity
      })
    },
    // 查询领用渠道
    queryChannels () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: 'F8898BDD4BCWEIGHSIEOWIDWOQURTHJDNEI',
          },
        ],
      }
      this.$queryDict.queryDict(info).then(res => {
        this.channelsList = res.resultEntity
      })
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 130px;
//@import url(); 引入公共css类
// 最外层div
.mainbody {
  height: 100%;
  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    // height: calc(100% - #{$topSelectHeight});
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.search_column1 {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
}
.search_column2 {
  margin-top: 20px;
}
.el-button--text {
  background: #00c9d6;
  border-radius: 0;
  color: white;
  height: 32px;
  width: 60px;
}
.pailie {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .el-form-item {
    margin-bottom: 20px;
  }
}
</style>

